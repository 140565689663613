/*
import React, { useState } from 'react';
import './Shop.css'; 
import { CiStar } from "react-icons/ci";
import { Helmet } from 'react-helmet';
import { IoIosArrowDown } from "react-icons/io";
import { shopItemOne,
    shopItemTwo,
    shopItemThree,
    shopItemFour,
    shopItemFive,
    shopItemSix,
    shopItemSeven,
    shopItemEight,
    shopItemNine } from '../../../assist/index';

const shopItems = [
    {
        id: 1,
        name: 'Assorted Small Succulents',
        price: 10,
        image: shopItemOne,
        reviews: '5 reviews',
        ratings: 0 // 0 out of 5 stars
    },
    {
        id: 2,
        name: 'Small Aloe Vera',
        price: 10,
        image: shopItemTwo,
        reviews: '8 reviews',
        ratings: 0
    },
    {
        id: 3,
        name: 'Medium Bonsai',
        price: 25,
        image: shopItemThree,
        reviews: '15 reviews',
        ratings: 0
    },
    {
        id: 4,
        name: 'Medium Orchid',
        price: 30,
        image: shopItemFour,
        reviews: '10 reviews',
        ratings: 0
    },
    {
        id: 5,
        name: 'Large White Peace Lily',
        price: 40,
        image: shopItemFive,
        reviews: '20 reviews',
        ratings: 0
    },
    {
        id: 6,
        name: 'Large Monstera (Swiss Cheese) Plant',
        price: 75,
        image: shopItemSix,
        reviews: '25 reviews',
        ratings: 0
    },
    {
        id: 7,
        name: 'Large Snake Plant',
        price: 75,
        image: shopItemSeven,
        reviews: '30 reviews',
        ratings: 0
    },
    {
        id: 8,
        name: 'Large Cactus',
        price: 85,
        image: shopItemEight,
        reviews: '18 reviews',
        ratings: 0
    },
    {
        id: 9,
        name: 'Office Bundle',
        price: 225,
        image: shopItemNine,
        reviews: '12 reviews',
        ratings: 0
    }
    ];
    

  const Shop = () => {
    const [plantDropdown, setPlantDropdown] = useState(false);
    const [potDropdown, setPotDropdown] = useState(false);
    const [giftDropdown, setGiftDropdown] = useState(false);
    const [allGiftDropdown, setAllGiftDropdown] = useState(false);
   
    const handleClick = (dropdownSetter) => {
      // Function to toggle specific dropdown
      dropdownSetter(current => !current);
    }

    return (
      <>
        <Helmet>
          <title>SWEET BAE | Shop</title>
          <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
        </Helmet>
        <p className="discount">30% off spring sale</p>
        <div className="wrapper">
        <div className="shop-sidebar">
          <div className="shop-filter-sidebar">
            <div class="shop-filter-section" onClick={() => handleClick(setPlantDropdown)}>
              <h4>All Plants <IoIosArrowDown className={`arrow-icon ${plantDropdown ? 'rotate' : ''}`}/></h4>
            </div>
            <div class="shop-filter-section" onClick={() => handleClick(setPotDropdown)}>
              <h4>All Pots <IoIosArrowDown className={`arrow-icon ${potDropdown ? 'rotate' : ''}`}/></h4>
            </div>
            <div class="shop-filter-section" onClick={() => handleClick(setGiftDropdown)}>
              <h4 
              onClick={(e) => {
                e.stopPropagation();
                handleClick(setAllGiftDropdown);
              }}
              >All Gifts <IoIosArrowDown className={`arrow-icon ${giftDropdown ? 'rotate' : ''}`}/></h4>
              {allGiftDropdown && (
                <div class="shop-filter-section-submenu">
                  <input
                    type="checkbox"
                    id="event"
                    name="gift-type"
                    value="event"
                  />
                  <label for="event">Event</label><br />
                  <input
                    type="checkbox"
                    id="corporate"
                    name="gift-type"
                    value="corporate"
                  />
                  <label for="corporate">Corporate</label><br />
                  <input
                    type="checkbox"
                    id="ideas"
                    name="gift-type"
                    value="ideas"
                  />
                  <label for="ideas">Other Ideas</label>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="main">
          <div className="shop-hero-image">
              <div className="shop-hero-content">
                <h1>Large and small,<br />We've got it all.</h1>
                <button>Shop all</button>
              </div>
          </div> 
          <div class="gift">
            <div class="gift-header">
              <div class="gift-header-content">
                <h2>All Gifts</h2>
              </div>
            </div>
          </div>
          <div className="shop-grid">
            {shopItems.map(plant => (
              <div className="plant-item" key={plant.id}>
                <img src={plant.image} alt={plant.name} />
                <div className="description">
                  <div className="info">
                    <span className="name"><h4>{plant.name}</h4></span>
                  </div>
                  <span className="price">${plant.price}</span>
                </div>
                <div className="meta">
                  <span className="ratings">
                    <CiStar className="ratings-star"/>
                    <CiStar className="ratings-star"/>
                    <CiStar className="ratings-star"/>
                    <CiStar className="ratings-star"/>
                    <CiStar className="ratings-star"/>
                  </span>
                  <span className="reviews">{plant.reviews}</span>
                </div>
              </div>
            ))}
          </div>
          </div>
        </div>
        <div className="shop-bottom-hero-image">
          <div className="shop-bottom-hero-content">
            <h2>Not sure<br />where<br />to start?<br />We do.</h2>
            <p>We're here to help.</p>
            <button>Talk to us</button>
          </div>
        </div>
      </>
    );
  };
    
export default Shop;
*/