import React, { useState, useEffect, useRef } from "react";
import {
  FaSquareFacebook,
  FaInstagram,
  FaWhatsapp,
  FaToggleOn,
} from "react-icons/fa6";
import { IoMdMenu, IoMdClose, IoIosArrowDown } from "react-icons/io";
import "./Navbar.css";
import { navHoverImg } from "../../../assist/index";
import ScrollDownEffect from "../../../variants/scrollDownEffect";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [subDropdown, setSubDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const megaMenuRef = useRef(null);
  const navbarRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const megaMenuContainerRef = useRef(null);
  const navLinksRef = useRef(null);

  const toggleMenu = () => {
    setIsActive((prev) => !prev);
  };

  const handleClick = (dropdownSetter) => {
    setActiveDropdown((prevDropdown) =>
      prevDropdown === dropdownSetter ? null : dropdownSetter
    );
  };

  const handleSubClick = (subDropdownSetter) => {
    setSubDropdown((prevSubDropdown) =>
      prevSubDropdown === subDropdownSetter ? null : subDropdownSetter
    );
  };

  const handleClickOutside = (event) => {
    const clickedInsideDropdown =
      dropdownContainerRef.current && dropdownContainerRef.current.contains(event.target);
  
    const clickedInsideMegaMenu =
      megaMenuContainerRef.current && megaMenuContainerRef.current.contains(event.target);
  
    const clickedInsideNavLinks =
      navLinksRef.current && navLinksRef.current.contains(event.target);
  
    // If the click is not inside dropdown, mega menu, or nav links container, then close
    if (!clickedInsideDropdown && !clickedInsideMegaMenu && !clickedInsideNavLinks) {
      setIsActive(false);
      setActiveDropdown(null);
      setSubDropdown(null);
    }
  };  
  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const toggleTheme = () => {
      const body = document.body;
      const currentTheme = body.getAttribute("data-theme");
      const newTheme = currentTheme === "dark" ? "light" : "dark";
      body.setAttribute("data-theme", newTheme);
      localStorage.setItem("theme", newTheme);
    };

    window.toggleTheme = toggleTheme;

    const savedTheme = localStorage.getItem("theme") || "dark";
    document.body.setAttribute("data-theme", savedTheme);
  }, []);

  return (
    <div>
      <nav className="navbar" ref={navbarRef}>
        <div className="logo">
          <a href="/">Sweet Bae</a>
          <button className="theme-toggle" onClick={() => window.toggleTheme()}>
            <FaToggleOn />
          </button>
        </div>
        <div className={`nav-lower ${isActive ? "active" : ""}`}>
          <div className="close-icon-item" onClick={toggleMenu}>
            {isActive && <IoMdClose />}
          </div>
          <ul className={`nav-links ${isActive ? "show" : "hide"}`} ref={navLinksRef}>
            <li>
              <Link to="/plant-directory" reloadDocument>
                Plants Directory
              </Link>
            </li>
            <li>
              <Link to="/blog" reloadDocument>
                Blog
              </Link>
            </li>

            <li className={`nav-dropdown ${activeDropdown === "caseStudies" ? "active" : ""}`}>
              <Link onClick={(e) => { e.stopPropagation(); handleClick("caseStudies") }}>
                Case Studies
                <IoIosArrowDown />
              </Link>
              <div
                ref={dropdownContainerRef}
                className={`dropdown-content ${activeDropdown === "caseStudies" ? "active" : ""}`}
                onClick={(e) => e.stopPropagation()} 
              >
                <ul
                  className={`nav-dropdown ${
                    subDropdown === "allCaseStudies" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubClick("allCaseStudies");
                  }}
                >
                  <li>
                      <Link>
                        Case Studies
                        <IoIosArrowDown
                          className={`nav-arrow-icon ${
                            subDropdown === "allCaseStudies" ? "rotate" : ""
                          }`}
                        />
                      </Link>
                      <ul
                        className={`sub-dropdown-content ${
                          subDropdown === "allCaseStudies" ? "active" : ""
                        }`}
                      >
                      <li>
                        <Link to="/case-study" reloadDocument>
                          Office Plant Installation
                        </Link>
                      </li>
                      <li>
                        <Link to="/case-study" reloadDocument>
                          Resort Plant Maintenance
                        </Link>
                      </li>
                      <li>
                        <Link to="/case-study" reloadDocument>
                          Custom Planter Design
                        </Link>
                      </li>
                      <li>
                        <Link to="/case-study" reloadDocument>
                          Education Plant Classes
                        </Link>
                      </li>
                      <li>
                        <Link to="/case-study" reloadDocument>
                          Seasonal Planting in park
                        </Link>
                      </li>
                      <li>
                        <Link to="/case-study" reloadDocument>
                          Plant Health Diagnosis in Residence Garden
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to="/all-case-studies" reloadDocument>
                      All Case Studies
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className={`nav-dropdown megamenu ${activeDropdown === "services" ? "active" : ""}`}>
                <Link onClick={(e) => { e.stopPropagation(); handleClick("services"); }}>
                  Services
                  <IoIosArrowDown />
                </Link>
                <div
                  className={`submenu-container ${activeDropdown === "services" ? "active" : ""}`}
                  ref={megaMenuContainerRef}
                  onClick={(e) => e.stopPropagation()}
                >
                <ul className="hover-links">
                  <li>
                    <Link to="/plant-install" reloadDocument>
                      Plant Installation
                    </Link>
                  </li>
                  <li>
                    <Link to="/plant-maint" reloadDocument>
                      Plant Maintenance
                    </Link>
                  </li>
                  <li>
                    <Link to="/plant-design" reloadDocument>
                      Custom Planter Design
                    </Link>
                  </li>
                  <li>
                    <Link to="/plant-classes" reloadDocument>
                      Plant Classes
                    </Link>
                  </li>
                  <li>
                    <Link to="/seasonal-planting" reloadDocument>
                      Seasonal Planting
                    </Link>
                  </li>
                  <li>
                    <Link to="/plant-health" reloadDocument>
                      Plant Health Diagnosis
                    </Link>
                  </li>
                  <li>
                    <Link to="/all-services" reloadDocument>
                      All Services
                    </Link>
                  </li>
                </ul>
                <div
                    className="hover-image"
                    style={{
                      backgroundImage: `url(${navHoverImg})`,
                    }}
                  ></div>
              </div>
            </li>
          </ul>
          <div className="toggleMenu" onClick={toggleMenu}>
            {!isActive && <IoMdMenu className="open-menu" />}
          </div>
        </div>

        <div className={`nav-upper ${isActive ? "active" : ""}`}>
          <div className="nav-socials">
            <Link to="/">
              <FaSquareFacebook />
            </Link>
            <Link to="/">
              <FaInstagram />
            </Link>
            <Link to="/">
              <FaWhatsapp />
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
