import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { MdLabelImportant } from "react-icons/md";
import {
    plantMaintSlideOne,
    plantMaintSlideTwo
} from '../../../assist/index';
import './Services.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import pageTransition from '../../../variants/pageTransition';

const maintenanceService = {
    overview: "Ensure the longevity and vibrancy of your landscapes with our professional Plant Maintenance services. Our experienced team offers routine care, seasonal adjustments, and specialized treatments to keep your plants healthy and flourishing. Our services are perfect for both residential and commercial settings, maintaining the beauty and health of your gardens throughout the year.",
    capabilities: [
      "Regular health checks and pruning to promote growth and prevent disease.",
      "Seasonal fertilization and mulching to optimize plant nutrition and soil moisture.",
      "Pest and disease management using environmentally friendly methods to protect your plants.",
      "Irrigation system maintenance to ensure efficient water usage and plant hydration.",
      "Custom care schedules tailored to the specific needs of each plant species in your garden."
    ],
    recognition: "Our dedication to plant health and customer satisfaction has earned us accolades in the landscaping community, including the Healthy Habitat Award and frequent mentions in 'Eco Gardens' magazine.",
    detailedServices: [
      {
        title: "Year-Round Plant Care",
        description: "Comprehensive maintenance packages to keep your residential or commercial gardens in peak condition, no matter the season."
      },
      {
        title: "Eco-Friendly Pest Management",
        description: "Utilizing sustainable practices to manage pests and diseases, ensuring your plants remain healthy and your property stays eco-conscious."
      },
      {
        title: "Advanced Irrigation Solutions",
        description: "Expert irrigation system checks and upgrades to reduce water waste and enhance plant growth."
      }
    ],
  };
  
const PlantMaint = () => {
    return (
    <div>
        <Helmet>
            <title>Sweet Bae | Comprehensive Plant Maintenance Services</title>
            <meta name="description" content="Discover our expert plant maintenance services designed to keep your residential and commercial landscapes healthy and beautiful. Learn about our eco-friendly pest management, advanced irrigation solutions, and more." />
        </Helmet>
        <AnnouncementBar />
        <div>
        <div className="service-hero hero">
                <h1>
                    Plant Maintenance
                </h1>
        </div>
        </div>
        <section>
            <div className="service-details-layout">
                <div className="service-text">
                <p>{maintenanceService.overview}</p>
                <div>
                    <h2>Our Capabilities</h2>
                    <ul>
                    {maintenanceService.capabilities.map((capability, index) => (
                        <li key={index}>{capability}</li>
                    ))}
                    </ul>
                </div>
                <div>
                    <h2>Recognition</h2>
                    <p>{maintenanceService.recognition}</p>
                </div>
                <hr className="grey" />
            <div>
                <h3>What We Offer</h3>
                <ol>
                    {maintenanceService.detailedServices.map((detail, index) => (                        
                      <li key={index}>
                          <strong>{detail.title}</strong>
                          <br />{detail.description}
                      </li>                    
                    ))}
                </ol>
          </div>
        </div>
                <div className="service-images">
                <img src={plantMaintSlideOne} alt="Eco-Friendly Pest Management" />
                <img src={plantMaintSlideTwo} alt="Advanced Irrigation Solutions" />
                </div>
            </div>
        </section>
        <section>
            <div className="service-bottom-hero promo-section">
                <div className="promo-content">
                    <h2>
                        Restore and Protect <br/>
                        Your Garden's Health!
                    </h2>
                    <h3>Contact us today!</h3>
                    <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
                </div>
            </div>
        </section>
    </div>
    );
}

export default pageTransition(PlantMaint);