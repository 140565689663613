// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plant-layout {
    display: flex;
    align-items: flex-start;
    gap: 2em;
    position:relative;
    padding-bottom:2em;
}
.plant-details h1 {
    font-size:42px;
    text-transform:none;
    margin-top:0;
}
.plant-details h2,
.plant-details h3  {
    font-size:24px;
    text-transform:none;
    margin:0.5em 0 0.25em
}
.plant-image {
    flex: 1 1;
    position:sticky;
    top:20px;
}

.plant-image img {
    width: 100%;
}

.plant-details {
    flex: 2 1;
}


@media (max-width: 991px) {
    .plant-layout {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    .plant-image {
        max-width: 100%;
        position:static;
    }

    .plant-image img {
        height: auto;
        object-fit: cover;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/individualPlant/IndividualPlant.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,mBAAmB;IACnB,YAAY;AAChB;AACA;;IAEI,cAAc;IACd,mBAAmB;IACnB;AACJ;AACA;IACI,SAAO;IACP,eAAe;IACf,QAAQ;AACZ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,SAAO;AACX;;;AAGA;IACI;QACI,sBAAsB;QACtB,mBAAmB;QACnB,UAAU;IACd;;IAEA;QACI,eAAe;QACf,eAAe;IACnB;;IAEA;QACI,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".plant-layout {\n    display: flex;\n    align-items: flex-start;\n    gap: 2em;\n    position:relative;\n    padding-bottom:2em;\n}\n.plant-details h1 {\n    font-size:42px;\n    text-transform:none;\n    margin-top:0;\n}\n.plant-details h2,\n.plant-details h3  {\n    font-size:24px;\n    text-transform:none;\n    margin:0.5em 0 0.25em\n}\n.plant-image {\n    flex: 1;\n    position:sticky;\n    top:20px;\n}\n\n.plant-image img {\n    width: 100%;\n}\n\n.plant-details {\n    flex: 2;\n}\n\n\n@media (max-width: 991px) {\n    .plant-layout {\n        flex-direction: column;\n        align-items: center;\n        padding: 0;\n    }\n\n    .plant-image {\n        max-width: 100%;\n        position:static;\n    }\n\n    .plant-image img {\n        height: auto;\n        object-fit: cover;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
