import React from "react";
import { Link } from "react-router-dom";
import { shopItemsImgOne, shopItemsImgTwo, shopItemsImgThree } from "../../assist/index";

import { serviceImgOne, serviceImgTwo, serviceImgThree } from "../../assist/index"; // Import product images

const blogPosts = [
  {
    img: shopItemsImgOne,
    alt: "10 Low-Maintenance Indoor Plants for Beginners",
    date: "May 17, 2024",
    title: "10 Low-Maintenance Indoor Plants for Beginners",
    link: "/individual-blog",
  },
  {
    img: shopItemsImgTwo,
    alt: "How to Revive a Dying Houseplant",
    date: "April 05, 2024",
    title: "How to Revive a Dying Houseplant",
    link: "/individual-blog",
  },
  {
    img: shopItemsImgThree,
    alt: "Best Air-Purifying Plants for a Healthier Home",
    date: "February 28, 2024",
    title: "Best Air-Purifying Plants for a Healthier Home",
    link: "/individual-blog",
  },
]


const BlogSidebar = () => (
  <div className="sidebar-right">
    {/* Contact Us Widget */}
    <div className="widget-contact-us">
      <h3>Need assistance? We're here to help!</h3>
      <Link className="button" to="/contact" reloadDocument>
        Contact Us
      </Link>
    </div>

    {/* Featured Posts */}
    <div className="widget featured-posts">
      <h2>Featured Posts</h2>
      <div className="post">
        {blogPosts.map((post, index) => (
          <Link key={index} className="post-item" to={post.link} reloadDocument>
            <img src={post.img} alt={post.alt} />
            <div className="post-content">
              <p>{post.date}</p>
              <h3>{post.title}</h3>
              <p className="read-more">Read More</p>
            </div>
          </Link>
        ))}
      </div>
    </div>

  </div>
);

export default BlogSidebar;
