import React from "react";
import { Link } from "react-router-dom";
import "./Body.css";

const Caretips = () => {
  return (
    <>
      <section>
        <div className="promo-section care-tips">
          <div className="promo-content">
            <h2>Easy Care Tips for Your Plants</h2>
            <h3>We're here to help.</h3>
            <Link className="button" to="/blog" reloadDocument>
              Read Tips
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Caretips;
