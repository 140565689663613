import React, {  useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { slideImgOne, slideImgTwo, slideImgThree } from '../../assist/index';
import './Body.css';

const HeroSection = () => {
    const [slideIndex, setSlideIndex] = useState(0);
    const backgroundImages = [slideImgOne, slideImgTwo, slideImgThree];
    const maxIndex = backgroundImages.length - 1;
    let intervalId;

    const changeBackgroundImage = useCallback(() => {
        const heroSection = document.querySelector('.hero');
        heroSection.style.backgroundImage = `url(${backgroundImages[slideIndex]})`;
    }, [slideIndex, backgroundImages]);

    const startAutoSlide = () => {
        intervalId = setInterval(() => {
            setSlideIndex(currentIndex => (currentIndex + 1) % (maxIndex + 1));
        }, 5000);
    };

    const resetAutoSlide = () => {
        clearInterval(intervalId);
        startAutoSlide();
    };

    useEffect(() => {
        startAutoSlide();
        return () => clearInterval(intervalId);
    }, [maxIndex]);

    const updateDots = useCallback(() => {
        const dots = document.querySelectorAll('.dot');
        dots.forEach((dot, index) => {
            if (index === slideIndex) {
                dot.classList.add('active');
            } else {
                dot.classList.remove('active');
            }
        });
    }, [slideIndex]);

    useEffect(() => {
        changeBackgroundImage();
        updateDots();
        const dots = document.querySelectorAll('.dot');

        const handleClick = (index) => () => {
            setSlideIndex(index);
            resetAutoSlide(); // Reset the auto slide timer when a dot is clicked
        };
        dots.forEach((dot, index) => {
            dot.addEventListener('click', handleClick(index));
        });
        return () => {
            dots.forEach((dot, index) => {
                dot.removeEventListener('click', handleClick(index));
            });
        };
    }, [changeBackgroundImage, updateDots]);

    useEffect(() => {
        const interval = setInterval(changeBackgroundImage, 3000); // Change image every 3 seconds
        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [changeBackgroundImage]);

    return (
        <>
            <p class="discount">30% off spring sale</p>
            <section className="hero" style={{ backgroundImage: `url(${backgroundImages[slideIndex]})` }}>
                <div className="hero-content">
                    <h1>Hello Spring</h1>
                    <button><Link to="/plant-directory" reloadDocument>Shop Sale</Link></button>
                    <div className="slideshow-controls">
                        {backgroundImages.map((_, idx) => (
                            <span key={idx} className={`dot ${idx === slideIndex ? 'active' : ''}`}
                                onClick={() => setSlideIndex(idx)}
                                aria-label={`Background slide ${idx + 1}`}></span>
                        ))}
                    </div>
                </div>
            </section> 
        </>

    );
};

export default HeroSection;
