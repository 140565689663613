import React, { useState, useEffect } from 'react';
import './PlantDirectory.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import {  shopItemOne,  
        shopItemTwo,
        shopItemThree,
        shopItemFour,
        shopItemFive,
        shopItemSix,
        shopItemSeven,
        shopItemEight,
        shopItemNine,
        avatarOne,
        avatarTwo,
        avatarThree,
        avatarFour } from '../../../assist/index';
import pageTransition from '../../../variants/pageTransition';

const plantItems = [
  {
    label: "Sold by Sweet Bae",
    img: shopItemOne,
    category: "Plant",
    name: "Assorted Small Succulents",
    ratings: "★★★★★",
    reviews: "5 reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Sweet Bae",
    img: shopItemTwo,
    category: "Plant",
    name: "Small Aloe Vera",
    ratings: "★★★★★",
    reviews: "8 reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Sweet Bae",
    img: shopItemThree,
    category: "Plant",
    name: "Medium Bonsai",
    ratings: "★★★★★",
    reviews: "15 reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Sweet Bae",
    img: shopItemFour,
    category: "Plant",
    name: "Medium Orchid",
    ratings: "★★★★★",
    reviews: "2 reviews",
    path: "/individual-plant"
  },
  {
    label: "Sold by Sweet Bae",
    img: shopItemFive,
    category: "Plant",
    name: "Large White Peace Lily",
    ratings: "★★★★★",
    reviews: "20 reviews",
    path: "/individual-plant"
  },
  /*
  {
    img: shopItemSix,
    category: "Houseplants",
    name: "Large Monstera (Swiss Cheese) Plant",
    ratings: "★★★★★",
    reviews: "5 reviews",
    path: "/individual-plant"
  },
  */
  {
    img: shopItemSeven,
    category: "Houseplants",
    name: "Large Snake Plant",
    ratings: "★★★★★",
    reviews: "33 reviews",
    path: "/individual-plant"
  },
  {
    img: shopItemEight,
    category: "Plant",
    name: "Large Cactus",
    ratings: "★★★★★",
    reviews: "8 reviews",
    path: "/individual-plant"
  },
  {
    img: shopItemNine,
    category: "Office Plants",
    name: "Office Bundle",
    ratings: "★★★★★",
    reviews: "6 reviews",
    path: "/individual-plant"
  }
];

const testimonials = [
  {
    img: avatarOne,
    testimonial: "I love the variety of succulents! Each one is unique and looks perfect in my small garden. I was amazed at the care taken in packaging them to ensure they arrived safely. It's been such a joy to watch them thrive in my garden, and I'm already planning to buy more. The quality is fantastic, and I highly recommend these plants for anyone looking to add something special to their outdoor space.",
    name: "Matthew H.",
    location: "Vancouver, BC",
    date: "August 15, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarTwo,
    testimonial: "This Orchid was a gift for my mother, and she loved it. The flowers are beautiful and so vibrant, and it truly brightened up her living room. It arrived in perfect condition, and the care instructions were clear and easy to follow. We were both impressed with how healthy the plant was, and it’s been flourishing ever since. I'll definitely be ordering from here again for future gifts.",
    name: "Megan T.",
    location: "Montreal, QC",
    date: "July 3, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarThree,
    testimonial: "The Orchid is gorgeous! It added a lot of elegance to my home. I've had so many compliments from friends and family who visited, and they couldn't believe how vibrant the flowers are. It was clear that great care was taken in the shipping process because it arrived in pristine condition. I’m really happy with how it’s thriving, and I’m excited to see it continue to grow.",
    name: "Gray F.",
    location: "Toronto, ON",
    date: "August 8, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarThree,
    testimonial: "Absolutely stunning Bonsai! It’s a centerpiece in my living room, and I’ve received so many compliments on it. The craftsmanship and care put into this Bonsai are evident, and it’s been such a joy to take care of. I was impressed with how carefully it was packaged, ensuring it arrived in perfect condition. It’s truly a work of art and has become one of my favorite pieces in the house.",
    name: "Tom L.",
    location: "Calgary, AB",
    date: "June 10, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarFour,
    testimonial: "The Aloe Vera plant is thriving! It’s great for skincare and looks lovely on my windowsill. I love how versatile Aloe Vera is, and this one is growing beautifully. I use it for so many things, from skincare to soothing minor cuts and burns. I also appreciate how low maintenance it is – perfect for someone with a busy schedule. This has quickly become one of my favorite plants.",
    name: "Hannah G.",
    location: "Vancouver, BC",
    date: "August 22, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarFour,
    testimonial: "The Peace Lily is such a beautiful plant! It was packaged perfectly and looks amazing in my living room. The dark green leaves contrast so well with the bright white flowers, and it brings such a peaceful and calming energy to the space. I’ve had it for a few months now, and it’s doing wonderfully. It’s a great reminder of the natural beauty that plants can bring to any home.",
    name: "Nina K.",
    location: "Vancouver, BC",
    date: "July 9, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarThree,
    testimonial: "The Peace Lily really brings life to my office space. So lush and vibrant! It has a beautiful shape and has quickly become a focal point in my workspace. I’ve noticed a difference in the overall atmosphere since adding it, and it’s been a wonderful addition. It was shipped quickly and arrived in excellent condition, ready to be displayed. I’m beyond happy with this purchase.",
    name: "Jess R.",
    location: "Toronto, ON",
    date: "September 2, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarOne,
    testimonial: "So happy with this purchase! The Orchid is blooming and looks so elegant. It was packaged with such care, and the instructions provided were clear, which helped me ensure it was properly taken care of. The flowers are stunning, and I couldn’t be more pleased with how they’ve brightened up my home. I’ll definitely be coming back for more plants in the future!",
    name: "John D.",
    location: "Vancouver, BC",
    date: "June 22, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarFour,
    testimonial: "It arrived in perfect condition, and it’s absolutely stunning. Highly recommend! From the moment I unpacked it, I knew this plant was something special. The care put into its packaging ensured it arrived without a single issue. It’s been thriving in my home, and I’ve received so many compliments on how beautiful and healthy it looks. I’m very impressed with the quality and service.",
    name: "Katie B.",
    location: "Calgary, AB",
    date: "August 28, 2024",
    rating: "★★★★★"
  },
  {
    img: avatarTwo,
    testimonial: "The best Orchid I’ve ever purchased! It's been a wonderful addition to my home, bringing a touch of nature and elegance to my living space. I’ve bought Orchids before, but none have been as healthy or vibrant as this one. It’s a testament to the care taken by the team in ensuring each plant is delivered in top condition. I couldn’t be happier with this purchase!",
    name: "Samantha C.",
    location: "Ottawa, ON",
    date: "July 15, 2024",
    rating: "★★★★★"
  }
];



const PlantDirectory = () => {

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 9000); 

    return () => clearInterval(interval); 
  }, [testimonials.length]);

  // Function to handle clicking on bullet
  const handleBulletClick = (index) => {
    setCurrentTestimonialIndex(index);
  };

  return (
    <div>
      <Helmet>
        <title>SWEET BAE | Plant Directory</title>
        <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="wrapper">
        <div className="main">
          <div className="shop-hero-image">
              <div className="shop-hero">
                <h1>Plant Directory</h1>
              </div>
          </div> 
          <div className="container">
            <div className="gift">
              <div className="gift-header">
                <div className="gift-header-content">
                  <p className="plant-intro">Find the perfect plants for your garden, big small. Search by plant name or specify your plant requirements and our handy plant finder will find the perfect match for you!</p>
                </div>
              </div>
            </div>
            <div className="shop-grid">
              {plantItems.map(plant => (
                <Link className="plant-item" to={plant.path} reloadDocument key={plant.id}>
                  {plant.label && <div className="label">{plant.label}</div>}
                    <img src={plant.img} alt={plant.name} />
                  <div className="description">
                    <div>
                      <span className="category"><h3>{plant.category}</h3></span>
                      <span className="name"><h4>{plant.name}</h4></span>
                    </div>
                  </div>
                  <div className="meta">
                    <span className="ratings">{plant.ratings}</span>
                    <span className="reviews">{plant.reviews}</span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          </div>
        </div>
        <div className="shop-testimonial">
          <h2>We make people smile!</h2>
          <p>What our customers are saying?</p>
          <div className="testimonial-container">
            <div className="testimonials">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`testimonial ${
                    index === currentTestimonialIndex ? 'active' : 'hidden'
                  }`}
                  style={{
                    display: index === currentTestimonialIndex ? 'block' : 'none',
                  }}
                >
                  <img src={testimonial.img} alt={testimonial.name} />
                  <p>{testimonial.testimonial}</p>
                  <div className="testimonial-rating">
                    {testimonial.rating}
                  </div>
                  <div className="testimonial-name">
                    <p>
                      {testimonial.name}
                    </p>
                  </div>
                  <div className="testimonial-location-date">
                    <p>
                      {testimonial.location}, {testimonial.date}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="testimonial-bullets">
              {testimonials.map((_, index) => (
                <span
                  key={index}
                  className={`bullet ${index === currentTestimonialIndex ? 'active' : ''}`}
                  onClick={() => handleBulletClick(index)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="shop-bottom-hero-image">
          <div className="shop-bottom-hero">
            <h2>Not sure where to start? We do.</h2>
            <h3>We're here to help.</h3>
            <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
          </div>
        </div>
    </div>
  )
}

export default pageTransition(PlantDirectory);
