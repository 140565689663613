import React from 'react';
import './Blog.css';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../../assist/index';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';

const blogPosts = [
  {
      imgSrc: blogImgOne,
      altText: "Wellness and Your Indoor Air Quality",
      title: "Wellness and Your Indoor Air Quality",
      date: "May 17, 2021"
  },
  {
      imgSrc: blogImgTwo,
      altText: "Simple Spring Projects",
      title: "Simple Spring Projects",
      date: "April 05, 2021"
  },
  {
      imgSrc: blogImgThree,
      altText: "Easy as Z to Z",
      title: "Easy as Z to Z",
      date: "February 28, 2021"
  },
  {
      imgSrc: blogImgThree,
      altText: "How to Master Z as Quickly as Possible",
      title: "How to Master Z as Quickly as Possible",
      date: "February 28, 2021"
  },
  {
      imgSrc: blogImgThree,
      altText: "Why Z is Crucial for Your Business Growth",
      title: "Why Z is Crucial for Your Business Growth",
      date: "February 28, 2021"
  },
  {
      imgSrc: blogImgThree,
      altText: "Top 5 Z Strategies Every Entrepreneur Should Know",
      title: "Top 5 Z Strategies Every Entrepreneur Should Know",
      date: "February 28, 2021"
  }
];


const Blog = () => {

  /*
  const maxTitleLength = 25; 
  const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
  */

  return (
    <div>
      <Helmet>
        <title>SWEET BAE | Blog</title>
        <meta name="description" content="Explore engaging ideas and practical tips on our blog." />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div className="blog-hero">
        <div className="blog-hero-overlay">
          <div className="blog-hero-content">
            <h1>
                The Potted Post
            </h1>
          </div>
        </div>
      </div>
        <div className='container'>
          <div className="blog-pottedposts">
              {blogPosts.map(post => (
                  <Link key={post.altText} to="/individual-blog" className="pottedpost">
                      <img src={post.imgSrc} alt={post.altText}/>
                      <div className="potted-blog-content">
                          <p>{post.date}</p>
                          <h3 title={post.title}>{(post.title)}</h3>
                          <button><Link to="/individual-blog" reloadDocument>Read More</Link></button>
                      </div>
                  </Link>
              ))}
          </div>
        </div>
        <hr/>
      </div>
  );
};


export default pageTransition(Blog);
