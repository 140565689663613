import { useState, useEffect } from 'react';

const useScrollEffect = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrollPosition(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollPosition;
};

const ScrollDownEffect = () => {
  const scrollPosition = useScrollEffect();

  useEffect(() => {
    const navbar = document.getElementById('navbar');
    const logo = document.getElementById('logo');

    if (navbar && logo) {
      if (scrollPosition > 80) {
        navbar.style.padding = '30px 10px';
        logo.style.fontSize = '25px';
      } else {
        navbar.style.padding = '80px 10px';
        logo.style.fontSize = '35px';
      }
    }
  }, [scrollPosition]);

  return null; 
};

export default ScrollDownEffect;
