import React from 'react';
import { Link } from 'react-router-dom';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../assist/index';

const blogPosts = [
    {
        id: 1,
        imgSrc: blogImgOne,
        altText: "10 Low-Maintenance Indoor Plants for Beginners",
        title: "10 Low-Maintenance Indoor Plants for Beginners",
        date: "May 17, 2024"
    },
    {
        id: 2,
        imgSrc: blogImgTwo,
        altText: "How to Revive a Dying Houseplant",
        title: "How to Revive a Dying Houseplant",
        date: "April 05, 2024"

    },
    {
        id: 3,
        imgSrc: blogImgThree,
        altText: "Best Air-Purifying Plants for a Healthier Home",
        title: "Best Air-Purifying Plants for a Healthier Home",
        date: "February 28, 2024"
    },
    {
        id: 4,
        imgSrc: blogImgOne,
        alt: "Best Air-Purifying Plants for a Healthier Home",
        date: "February 28, 2024",
        title: "Best Air-Purifying Plants for a Healthier Home Revisited",
    },
];

/*
const maxTitleLength = 25; 
const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
textLimit
*/

const BlogSection = () => {
  return (
    <div>
        <section className='blog-section'>
        <h2 className="h1">From The Potted Post</h2>
            <Link className="button" to="/blog" reloadDocument>View All Posts</Link>
            <div className="blog-posts">
                {blogPosts.map(post => (
                    <div key={post.id} className="post">
                        <div className="post-image">
                            <Link to="/individual-blog" reloadDocument><img src={post.imgSrc} alt={post.altText}/></Link>
                        </div>
                        <div className="blog-content">
                            <p>{post.date}</p>
                            <h3><Link to="/individual-blog" reloadDocument>{(post.title)}</Link></h3>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    </div>
  )
}

export default BlogSection