import React from 'react';
import { motion } from 'framer-motion';

const pageTransi = {
  in: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
  out: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: "easeInOut",
    },
  },
};

const pageTransition = (WrappedComponent) => {
  return (props) => (
    <motion.div
      initial="out"
      animate="in"
      exit="out"
      variants={pageTransi}
    >
      <WrappedComponent {...props} />
    </motion.div>
  );
};

export default pageTransition;
