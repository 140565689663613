import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { MdLabelImportant } from "react-icons/md";
import {
    plantClassSlideOne,
    plantClassSlideTwo
} from '../../../assist/index';
import './Services.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import pageTransition from '../../../variants/pageTransition';

const plantClasses = {
    overview: "Dive into the world of gardening with our Plant Classes. Designed for enthusiasts of all levels, our workshops offer hands-on learning about plant care, garden design, and sustainable gardening techniques. Whether you're a beginner or an experienced gardener, our classes provide valuable insights and skills to help you create and maintain vibrant gardens.",
    capabilities: [
      "Beginner Gardening Classes: Learn the basics of planting, soil preparation, and simple garden maintenance.",
      "Advanced Horticulture Workshops: Delve deeper into botany, plant physiology, and sophisticated garden design.",
      "Sustainable Gardening Techniques: Discover how to use water-efficient systems, organic fertilizers, and pest control methods.",
      "Seasonal Planting Guides: Understand the best plants for your region's climate and how to care for them throughout the year.",
      "DIY Garden Projects: Engage in creative projects like building raised beds, creating container gardens, and more."
    ],
    recognition: "Our classes have been acclaimed for their informative and engaging nature, praised in 'The Green Thumb Times' and awarded at the Community Education Excellence Conference.",
    detailedServices: [
      {
        title: "Home Gardener Series",
        description: "Tailored for homeowners looking to beautify their outdoor spaces, these classes cover everything from basic gardening techniques to landscape planning."
      },
      {
        title: "Professional Gardening Certification",
        description: "Intensive courses designed for landscape professionals seeking to enhance their skills and knowledge in advanced gardening techniques."
      },
      {
        title: "Children’s Gardening Club",
        description: "Fun and educational sessions to inspire the next generation of gardeners, focusing on simple, enjoyable gardening projects."
      }
    ],
  };
  
const PlantClass = () => {
    return (
        <div>
            <Helmet>
                <title>Sweet Bae | Plant Classes Services</title>
                <meta name="description" content="Join Sweet Bae's Plant Classes to enhance your gardening skills." />
            </Helmet>
            <AnnouncementBar />
            <div>
                <div className="service-hero hero">
                    <h1>
                        Plant Classes
                    </h1>
            </div>
            </div>
            <section>
                <div className="service-details-layout">
                    <div className="service-text">
                        <p>{plantClasses.overview}</p>
                        <div>
                            <h2>Our Capabilities</h2>
                            <ul>
                            {plantClasses.capabilities.map((capability, index) => (
                                <li key={index}>{capability}</li>
                            ))}
                            </ul>
                        </div>
                        <div>
                            <h2>Recognition</h2>
                            <p>{plantClasses.recognition}</p>
                        </div>
                        <hr className="grey" />
                        <div>
                            <h3>What We Offer</h3>
                            <ol>
                                {plantClasses.detailedServices.map((detail, index) => (
                                    
                                        <li key={index}>
                                            <strong>{detail.title}</strong>
                                            <br />{detail.description}
                                        </li>
                                
                                ))}
                            </ol>
                      </div>
                    </div>
                    <div className="service-images">
                        <img src={plantClassSlideOne} alt="Home Gardener Series" />
                        <img src={plantClassSlideTwo} alt="Children’s Gardening Club" />
                    </div>
                </div>
            </section>
            <section>
                <div className="service-bottom-hero promo-section">
                    <div className="promo-content">
                        <h2>
                            Restore and Protect <br/>
                            Your Garden's Health!
                        </h2>
                        <h3>Contact us today!</h3>
                        <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default pageTransition(PlantClass);