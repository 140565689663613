import React from 'react';
import { Helmet } from 'react-helmet-async';
import { individualPlant } from '../../../assist/index'; 
import './IndividualPlant.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import pageTransition from '../../../variants/pageTransition';

const IndividualPlant = () => {
  return (
    <div>
      <Helmet>
        <title>SWEET BEE | Individual Plant</title>
        <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
      </Helmet>
      <AnnouncementBar />
      <section className="individual-plant-container">
      <div className="plant-layout">
          <div className="plant-image">
            <img src={individualPlant} alt="Bailey Compact Amur Maple" />
          </div>
          <div className="plant-details">
            <h1>Spring Blossoming Tree</h1>
            
            <p>A majestic tree with a broad canopy and stunning spring blossoms. Its white flowers create a striking contrast against the clear blue sky, making it an ideal choice for gardens and parks.</p>
            
            <h2>Ornamental Features</h2>
            <p>The Spring Blossoming Tree is primarily grown for its spectacular spring flowers. The profuse white blossoms appear before the foliage, covering the tree in a blanket of white. The dark green leaves provide a beautiful backdrop as the flowers fade.</p>
            
            <h3>Landscape Attributes</h3>
            <p>This tree is a deciduous species with a broad, rounded form. Its fine texture and abundant blossoms make it a standout in any landscape. It is relatively low maintenance, requiring minimal pruning. The tree is best pruned in late winter or early spring before the new growth begins. It has no significant negative characteristics and is an excellent choice for ornamental planting.</p>
            
            <h3>Planting & Growing</h3>
            <p>The Spring Blossoming Tree will grow to a mature height of 10-15 meters with a spread of 8-12 meters. It prefers full sun and moist, well-drained soil. The tree is adaptable to a range of soil types and pH levels. It is hardy in zones 4-7 and can tolerate urban pollution, making it suitable for city environments. This tree can be expected to live for many years, providing beauty and shade.</p>
          </div>
        </div>
      </section>
      </div>
  );
}

export default pageTransition(IndividualPlant);
