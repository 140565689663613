import React, {useState} from 'react';
import './CaseStudies.css';
import { MdDescription, MdOutlineReportProblem, MdAutoFixHigh, MdCloudDone  } from "react-icons/md";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
    classPlantOne,
    classPlantTwo,
    classPlantThree
} from '../../../assist/index';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';

const classCaseStudies = [
    {
        description: "Launched educational plant classes aimed at empowering community members with essential gardening skills, focusing on sustainable practices and local flora.",
        challenge: "Local community members expressed a growing interest in gardening but lacked fundamental knowledge and skills, which hindered their ability to cultivate healthy and sustainable gardens.",
        solution: "Introduced a series of educational workshops that covered a range of topics, from basic plant care to advanced sustainable gardening techniques. The classes were taught by experienced horticulturists and included hands-on activities to enhance learning.",
        outcome: "The educational plant classes were highly successful, leading to an increased understanding of gardening and sustainability among participants. Feedback was overwhelmingly positive, with many attendees starting their own gardens and further promoting community green initiatives."
    }
];


const EducationPlantClass = () => {

    const [modal, setModal] = useState({ isOpen: false, imgSrc: '' });
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [classPlantOne, classPlantTwo, classPlantThree];

    const openModal = (imgSrc) => {
        setModal({ isOpen: true, imgSrc });
    };

    const closeModal = () => {
        setModal({ isOpen: false, imgSrc: '' });
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <section>
            <Helmet>
                <title>Education Plant Classes</title>
                <meta name="description" content="Discover our educational plant classes designed to equip community members with essential gardening skills, focusing on sustainability and local plant knowledge." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="case-hero-image">
                    <div className="case-hero">
                        <h1>
                            Education Plant Classes
                        </h1>
                    </div>
                </div>
            </div>
            <div className="case-images">
                <IoIosArrowBack className="arrow left" onClick={prevImage} />
                <div className="image-container">
                    <img
                        src={images[(currentImageIndex - 1 + images.length) % images.length]}
                        className="left-image"
                        alt="Previous Design"
                    />
                    <img
                        src={images[currentImageIndex]}
                        className="main-image"
                        alt="Current Design"
                        onClick={() => openModal(images[currentImageIndex])}
                    />
                    <img
                        src={images[(currentImageIndex + 1) % images.length]}
                        className="right-image"
                        alt="Next Design"
                    />
                </div>
                <IoIosArrowForward className="arrow right" onClick={nextImage} />
            </div>
            {modal.isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}><IoMdClose /></span>
                        <img src={modal.imgSrc} alt="Enlarged view" />
                    </div>
                </div>
            )}
            <div className="card-container">
                {classCaseStudies.map((study, index) => (
                    <div key={index} className="card">
                        <div className="card-item">
                            <MdDescription className="icon" />
                            <h2>Description</h2>
                            <p>{study.description}</p>
                        </div>
                        <div className="card-item">
                            <MdOutlineReportProblem className="icon" />
                            <h2>Challenge</h2>
                            <p>{study.challenge}</p>
                        </div>
                        <div className="card-item">
                            <MdAutoFixHigh className="icon" />
                            <h2>Solution</h2>
                            <p>{study.solution}</p>
                        </div>
                        <div className="card-item">
                            <MdCloudDone className="icon" />
                            <h2>Outcome</h2>
                            <p>{study.outcome}</p>
                        </div>
                    </div>
                ))}
            </div>
            <hr/>
        </section>
    )
}

export default pageTransition(EducationPlantClass);