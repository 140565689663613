// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-content h2 {
    text-transform:none;
    margin-top:1em;
}

.privacy-content a {
    text-decoration: none;
    color: var(--footer-page-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/privacy/Privacy.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,oCAAoC;AACxC","sourcesContent":[".privacy-content h2 {\n    text-transform:none;\n    margin-top:1em;\n}\n\n.privacy-content a {\n    text-decoration: none;\n    color: var(--footer-page-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
