import React, { useState, useEffect } from "react";
import "./CaseStudy.css";
import AnnouncementBar from "../../shared/AnnouncementBar";
import {
  MdDescription,
  MdOutlineReportProblem,
  MdAutoFixHigh,
  MdCloudDone,
} from "react-icons/md";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {
  classPlantOne,
  classPlantTwo,
  classPlantThree,
  plantDesignOne,
  plantDesignTwo,
} from "../../../assist/index";
import { Helmet } from "react-helmet-async";
import pageTransition from "../../../variants/pageTransition";

const classCaseStudies = [
  {
    description:
      "Launched educational plant classes aimed at empowering community members with essential gardening skills, focusing on sustainable practices and local flora.",
    challenge:
      "Local community members expressed a growing interest in gardening but lacked fundamental knowledge and skills, which hindered their ability to cultivate healthy and sustainable gardens.",
    solution:
      "Introduced a series of educational workshops that covered a range of topics, from basic plant care to advanced sustainable gardening techniques. The classes were taught by experienced horticulturists and included hands-on activities to enhance learning.",
    outcome:
      "The educational plant classes were highly successful, leading to an increased understanding of gardening and sustainability among participants. Feedback was overwhelmingly positive, with many attendees starting their own gardens and further promoting community green initiatives.",
  },
];

const IndividualCaseStudy = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [imagesPerView, setImagesPerView] = useState(3);
  const [modal, setModal] = useState({
    isOpen: false,
    imgSrc: "",
    isClosing: false,
  });

  const images = [
    classPlantOne,
    classPlantTwo,
    classPlantThree,
    plantDesignOne,
    plantDesignTwo,
  ];

  // Adjust totalSlides calculation
  const totalSlides = images.length - imagesPerView + 1;

  const openModal = (imgSrc) => {
    setModal({ isOpen: true, imgSrc, isClosing: false });
  };

  const closeModal = () => {
    setModal((prevState) => ({ ...prevState, isClosing: true }));
    setTimeout(() => {
      setModal({ isOpen: false, imgSrc: "", isClosing: false });
    }, 300); // Match the animation duration
  };

  const nextImage = () => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    }
  };

  const prevImage = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  useEffect(() => {
    const updateImagesPerView = () => {
      if (window.innerWidth <= 768) {
        setImagesPerView(1);
      } else {
        setImagesPerView(3);
      }
    };

    window.addEventListener("resize", updateImagesPerView);
    updateImagesPerView(); // Set initial value

    return () => window.removeEventListener("resize", updateImagesPerView);
  }, []);

  return (
    <>
      <Helmet>
        <title>Individual Case Study</title>
        <meta
          name="description"
          content="Discover our educational plant classes designed to equip community members with essential gardening skills, focusing on sustainability and local plant knowledge."
        />
      </Helmet>
      <AnnouncementBar />
      <div>
        <div className="case-hero hero">
          <div className="hero-content">
            <h1>Individual Case Study</h1>
          </div>
        </div>
      </div>
      <section>
        <div className="carousel case-images">
          {images.length > imagesPerView && (
            <IoIosArrowBack
              className={`arrow left ${currentSlide === 0 ? "disabled" : ""}`}
              onClick={prevImage}
            />
          )}
          <div
            className="carousel-inner"
            style={{
              width: `${(images.length * 100) / imagesPerView}%`,
              transform: `translateX(-${
                (currentSlide * 100) / imagesPerView
              }%)`,
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Slide ${index}`}
                onClick={() => openModal(image)}
                style={{ width: `${(100 * imagesPerView) / images.length}%` }}
              />
            ))}
          </div>
          {images.length > imagesPerView && (
            <IoIosArrowForward
              className={`arrow right ${
                currentSlide === totalSlides - 1 ? "disabled" : ""
              }`}
              onClick={nextImage}
            />
          )}
        </div>
      </section>
      {modal.isOpen && (
        <div
          className={`modal ${modal.isClosing ? "fade-out" : "fade-in"}`}
          onClick={closeModal} // Close modal when clicking on the background
        >
          <div
            className={`modal-content ${
              modal.isClosing ? "zoom-out" : "zoom-in"
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent propagation for clicks inside modal
          >
            <span className="close" onClick={closeModal}>
              <IoMdClose />
            </span>
            <img src={modal.imgSrc} alt="Enlarged view" />
          </div>
        </div>
      )}
      <section className="card-container">
        {classCaseStudies.map((study, index) => (
          <div key={index} className="card">
            <div className="card-item">
              <MdDescription className="icon" />
              <h2>Description</h2>
              <p>{study.description}</p>
            </div>
            <div className="card-item">
              <MdOutlineReportProblem className="icon" />
              <h2>Challenge</h2>
              <p>{study.challenge}</p>
            </div>
            <div className="card-item">
              <MdAutoFixHigh className="icon" />
              <h2>Solution</h2>
              <p>{study.solution}</p>
            </div>
            <div className="card-item">
              <MdCloudDone className="icon" />
              <h2>Outcome</h2>
              <p>{study.outcome}</p>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};

export default pageTransition(IndividualCaseStudy);
