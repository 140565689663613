import React from 'react';
import './About.css';
import { aboutImgOne, aboutImgTwo, aboutImgThree } from '../../../assist/index';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';

const aboutFeatures = [
  {
    src: aboutImgOne,
    alt: 'Lavender fields at Sweet Bae farm, overview',
    text: 'Cicely began in the countryside near Milton, Ontario in 2011 with the goal to grow a sustainable and organic lavender farm that allowed visitors to be immersed in a serene and relaxing environment. Starting initially with 10,000 lavender plants and a working apiary. After years of careful planning and cultivating, the farm opened to the visiting public in 2014 with a small shop that carried our first organic products made right from our crop.'
  },
  {
    src: aboutImgTwo,
    alt: 'Visitors enjoying the lavender fields at Sweet Bae farm',
    text: 'In the end, Cicely farm was home to over 75,000 lavender plants and herbs and flowers spread over 200 acres. The farm brought joy to thousands of visitors every summer.'
  },
  {
    src: aboutImgThree,
    alt: 'The new Sweet Bae boutique in Elora',
    text: 'While the original Cicely farm location has forever closed its gates to visitors, a new chapter has begun with the Yellow Door and the Cicely brand moving on in an all-new boutique in Elora. Shop all your favourite products in-store in our new retail boutique.'
  }
];

const About = () => {
  return (
    <>
    <Helmet>
      <title>SWEET BAE | Our Story</title>
      <meta name="description" content="
      Founded in 2011, SWEET BAE began as an organic lavender farm in Milton, Ontario, and now continues its legacy through a boutique in Elora offering beloved organic products." />
    </Helmet>
    <p className="discount">30% off spring sale</p>
    <section>
      <div class="about-hero-image">
        <div class="about-hero">
          <h1>Our Story</h1>
        </div>
      </div>
    </section>
    <section>
      <div className="container">
        {aboutFeatures.map((feature, index) => (
          <div key={index} className="about-feature">
            <div className="about-feature-image">
              <img src={feature.src} alt={feature.alt} />
            </div>
            <div className="about-feature-text">
              <p>{feature.text}</p>
            </div>
          </div>
        ))}
      </div>
      <hr/>
    </section>
    </>
  );
}

export default pageTransition(About);
;
