import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AnnouncementBar from '../shared/AnnouncementBar';
import { slideImgOne, slideImgTwo, slideImgThree } from '../../assist/index';
import './Body.css';

const HeroSection = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const backgroundImages = [slideImgOne, slideImgTwo, slideImgThree];
  const maxIndex = backgroundImages.length - 1;

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((currentIndex) => (currentIndex + 1) % (maxIndex + 1));
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [maxIndex]);

  // Handle dot navigation
  const handleDotClick = (index) => {
    setSlideIndex(index);
  };

  return (
    <>
      <AnnouncementBar />
      <section className="hero slideshow">
        <div className="hero-slides">
          {backgroundImages.map((imgSrc, idx) => (
            <div
              key={idx}
              className={`hero-slide ${idx === slideIndex ? 'active' : ''}`}
              style={{ backgroundImage: `url(${imgSrc})` }}
            ></div>
          ))}
        </div>
        <div className="hero-content">
          <h1>Hello Spring</h1>
          <Link className="button" to="/plant-directory" reloadDocument>
            Shop Sale
          </Link>
          <div className="slideshow-controls">
            {backgroundImages.map((_, idx) => (
              <span
                key={idx}
                className={`dot ${idx === slideIndex ? 'active' : ''}`}
                onClick={() => handleDotClick(idx)}
                aria-label={`Background slide ${idx + 1}`}
              ></span>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroSection;
