import React from 'react';
import { Link } from 'react-router-dom';
import { plantInstallSlideOne,
        plantClassSlideOne,
        plantHealthOne,
        plantDesignOne
} from '../../assist/index';
import './Body.css'

const shopByItems = [
    {
        imgSrc: plantClassSlideOne,
        altText: "Plant Classes",
        linkTo: "/plant-classes",
        buttonText: "Plant Classes"
    },
    {
        imgSrc: plantDesignOne,
        altText: "Plant Design",
        linkTo: "/plant-design",
        buttonText: "Plant Design"
    },
    {
        imgSrc: plantHealthOne,
        altText: "Plant Health",
        linkTo: "/plant-health", 
        buttonText: "Plant Health"
    },
    {
        imgSrc: plantInstallSlideOne,
        altText: "Plant Installation",
        linkTo: "/plant-install",
        buttonText: "Plant Installation"
    }
];

const shopBysection = () => {
  return (
    <>
        <section className="services">
            <h2 className="h1">Services</h2>
            <Link className="button" to="/all-services" reloadDocument>View All Services</Link>
            <div className="service-items">
                {shopByItems.map(item => (
                    <div key={item.altText} className="service-item" style={{ backgroundImage: `url(${item.imgSrc})` }}>
                        <div className="service-item-content">
                            <Link className="button" to={item.linkTo} reloadDocument>{item.buttonText}</Link>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    </>
  )
}

export default shopBysection