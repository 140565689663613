// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assist/images/services/plantHero.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../assist/images/services/serviceBottomCTA.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-hero {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

.service-bottom-hero {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
.service-details-layout {
    display: flex;
    align-items: flex-start;
    gap: 5em;
    position:relative;
}

.service-text {
    flex: 2 1;
}

.service-text h2 {
    font-size:32px;
    color: var(--accent-color);
    margin:0.5em 0 0.25em
}
.service-text h3 {
    margin:0 0 0.5em
}

.service-images {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    gap: 2em; 
    position:sticky;
    top:20px;
}

.service-images img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@media (max-width:991px) {
    .service-details-layout {
        gap:2em;
    }
}
@media (max-width:600px) {
    .service-details-layout {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/services/Services.css"],"names":[],"mappings":"AAAA;IACI,yDAAsE;AAC1E;;AAEA;IACI,yDAA6E;AACjF;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,QAAQ;IACR,iBAAiB;AACrB;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B;AACJ;AACA;IACI;AACJ;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,QAAQ;IAER,eAAe;IACf,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,qDAAqD;AACzD;;AAEA;IACI;QACI,OAAO;IACX;AACJ;AACA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".service-hero {\n    background-image: url('../../../assist/images/services/plantHero.jpg');\n}\n\n.service-bottom-hero {\n    background-image: url('../../../assist/images/services/serviceBottomCTA.jpg');\n}\n.service-details-layout {\n    display: flex;\n    align-items: flex-start;\n    gap: 5em;\n    position:relative;\n}\n\n.service-text {\n    flex: 2;\n}\n\n.service-text h2 {\n    font-size:32px;\n    color: var(--accent-color);\n    margin:0.5em 0 0.25em\n}\n.service-text h3 {\n    margin:0 0 0.5em\n}\n\n.service-images {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 2em;\n    position: -webkit-sticky; \n    position:sticky;\n    top:20px;\n}\n\n.service-images img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    transition: transform 0.3s ease, box-shadow 0.3s ease;\n}\n\n@media (max-width:991px) {\n    .service-details-layout {\n        gap:2em;\n    }\n}\n@media (max-width:600px) {\n    .service-details-layout {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
