import React from 'react';
import { Link } from 'react-router-dom';
import { blogImgOne, blogImgTwo, blogImgThree } from '../../assist/index';

const blogPosts = [
    {
        imgSrc: blogImgOne,
        altText: "10 Low-Maintenance Indoor Plants for Beginners",
        title: "10 Low-Maintenance Indoor Plants for Beginners",
        date: "May 17, 2024"
    },
    {
        imgSrc: blogImgTwo,
        altText: "How to Revive a Dying Houseplant",
        title: "How to Revive a Dying Houseplant",
        date: "April 05, 2024"

    },
    {
        imgSrc: blogImgThree,
        altText: "Best Air-Purifying Plants for a Healthier Home",
        title: "Best Air-Purifying Plants for a Healthier Home",
        date: "February 28, 2024"

    }
];

const PromoSection = () => {
    return (
        <section>
            <div className="promo-section">
                <div className="promo-content">
                    <h2>Our sweet bae promise to you</h2>
                    <h3>Quality you can trust.</h3>
                    <Link className="button" to="/plant-directory" reloadDocument>Read More</Link>
                </div>
            </div>
        </section>
    );
};

export default PromoSection;
