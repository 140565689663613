import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './AllCaseStudies.css';
import {
    allCaseStudiesImgOne,
    allCaseStudiesImgTwo,
    allCaseStudiesImgThree,
    allCaseStudiesImgFour,
    allCaseStudiesImgFive,
    allCaseStudiesImgSix
} from '../../../assist/index';
import pageTransition from '../../../variants/pageTransition';

const caseStudies = [
    {
      img: allCaseStudiesImgOne,
      title: "Plant Installation at Urban Office Complex",
      path: "/office-plant-install"
    },
    {
      img: allCaseStudiesImgTwo,
      title: "Ongoing Plant Maintenance for a Luxury Resort",
      path: "/resort-plant-maintenance"
    },
    {
      img: allCaseStudiesImgThree,
      title: "Custom Planter Design for Boutique Retailer",
      path: "/custome-plant-design"
    },
    {
      img: allCaseStudiesImgFour,
      title: "Educational Plant Classes at Community Center",
      path: "/education-plant-class"
    },
    {
      img: allCaseStudiesImgFive,
      title: "Seasonal Planting for City Park",
      path: "/park-season-plant"
    },
    {
      img: allCaseStudiesImgSix,
      title: "Plant Health Diagnosis for Residential Gardens",
      path: "/resident-garden-plant-health"
    }
  ];
  

const AllCaseStudies = () => {
  
    /*
    const maxTitleLength = 30; 
    const textLimit = (title) => title.length > maxTitleLength ? `${title.slice(0, maxTitleLength)}...` : title;
    */
    return (
    <div>
      <Helmet>
        <title>SWEET BAE | Case Studies</title>
        <meta name="description" content="All Case Studies" />
      </Helmet>
      <p className="discount">30% off spring sale</p>
      <div>
        <div className="allcasestudies-hero-image">
            <div className="allcasestudies-hero">
                <h1>
                    Case Studies
                </h1>
            </div>
        </div>
      </div>
      <div className="container">
        <div className="allcasestudies-grid">
        {caseStudies.map(caseStudies => (
          <Link to={caseStudies.path} className="allcasestudies-card" key={caseStudies.title} reloadDocument >
              <img src={caseStudies.img} alt={caseStudies.title} />
              <div className="service-overlay">
                <Link to={caseStudies.path} reloadDocument>
                    <h2>{(caseStudies.title)}</h2>
                </Link>
              </div> 
          </Link>
          ))}
        </div>
      </div>
      <hr/>
    </div>
    );
};

export default pageTransition(AllCaseStudies);