import React, { useState, useEffect } from 'react';
import './PlantDirectory.css';
import AnnouncementBar from '../../shared/AnnouncementBar';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

import {  shopItemOne,  
        shopItemTwo,
        shopItemThree,
        shopItemFour,
        shopItemFive,
        shopItemSix,
        shopItemSeven,
        shopItemEight,
        shopItemNine,
        avatarOne,
        avatarTwo,
        avatarThree,
        avatarFour } from '../../../assist/index';
import pageTransition from '../../../variants/pageTransition';

const plantItems = [
  {
    id: 1,
    label: "Sold by Sweet Bae",
    img: shopItemOne,
    category: "Plant",
    name: "Assorted Small Succulents",
    ratings: "★★★★★",
    reviews: "5 reviews",
    path: "/individual-plant"
  },
  {
    id: 2,
    label: "Sold by Sweet Bae",
    img: shopItemTwo,
    category: "Plant",
    name: "Small Aloe Vera",
    ratings: "★★★★★",
    reviews: "8 reviews",
    path: "/individual-plant"
  },
  {
    id: 3,
    label: "Sold by Sweet Bae",
    img: shopItemThree,
    category: "Plant",
    name: "Medium Bonsai",
    ratings: "★★★★★",
    reviews: "15 reviews",
    path: "/individual-plant"
  },
  {
    id: 4,
    label: "Sold by Sweet Bae",
    img: shopItemFour,
    category: "Plant",
    name: "Medium Orchid",
    ratings: "★★★★★",
    reviews: "2 reviews",
    path: "/individual-plant"
  },
  {
    id: 5,
    label: "Sold by Sweet Bae",
    img: shopItemFive,
    category: "Plant",
    name: "Large White Peace Lily",
    ratings: "★★★★★",
    reviews: "20 reviews",
    path: "/individual-plant"
  },
  {
    id: 6,
    img: shopItemSeven,
    category: "Houseplants",
    name: "Large Snake Plant",
    ratings: "★★★★★",
    reviews: "33 reviews",
    path: "/individual-plant"
  },
  {
    id: 7,
    img: shopItemEight,
    category: "Plant",
    name: "Large Cactus",
    ratings: "★★★★★",
    reviews: "8 reviews",
    path: "/individual-plant"
  },
  {
    id: 8,
    img: shopItemNine,
    category: "Office Plants",
    name: "Office Bundle",
    ratings: "★★★★★",
    reviews: "6 reviews",
    path: "/individual-plant"
  }
];

const testimonials = [
  {
    id: 1,
    img: avatarOne,
    testimonial: "I love the variety of succulents! Each one is unique and looks perfect in my small garden. I was amazed at the care taken in packaging them to ensure they arrived safely. It's been such a joy to watch them thrive in my garden, and I'm already planning to buy more. The quality is fantastic, and I highly recommend these plants for anyone looking to add something special to their outdoor space.",
    name: "Matthew H.",
    location: "Vancouver, BC",
    date: "August 15, 2024",
    rating: "★★★★★"
  },
  {
    id: 2,
    img: avatarTwo,
    testimonial: "This Orchid was a gift for my mother, and she loved it. The flowers are beautiful and so vibrant, and it truly brightened up her living room. It arrived in perfect condition, and the care instructions were clear and easy to follow. We were both impressed with how healthy the plant was, and it’s been flourishing ever since. I'll definitely be ordering from here again for future gifts.",
    name: "Megan T.",
    location: "Montreal, QC",
    date: "July 3, 2024",
    rating: "★★★★★"
  },
  {
    id: 3,
    img: avatarThree,
    testimonial: "The Orchid is gorgeous! It added a lot of elegance to my home. I've had so many compliments from friends and family who visited, and they couldn't believe how vibrant the flowers are. It was clear that great care was taken in the shipping process because it arrived in pristine condition. I’m really happy with how it’s thriving, and I’m excited to see it continue to grow.",
    name: "Gray F.",
    location: "Toronto, ON",
    date: "August 8, 2024",
    rating: "★★★★★"
  },
  {
    id: 4,
    img: avatarFour,
    testimonial: "The Aloe Vera plant is thriving! It’s great for skincare and looks lovely on my windowsill. I love how versatile Aloe Vera is, and this one is growing beautifully. I use it for so many things, from skincare to soothing minor cuts and burns. I also appreciate how low maintenance it is – perfect for someone with a busy schedule. This has quickly become one of my favorite plants.",
    name: "Hannah G.",
    location: "Vancouver, BC",
    date: "August 22, 2024",
    rating: "★★★★★"
  },
  {
    id: 5,
    img: avatarTwo,
    testimonial: "The Peace Lily is such a beautiful plant! It was packaged perfectly and looks amazing in my living room. The dark green leaves contrast so well with the bright white flowers, and it brings such a peaceful and calming energy to the space. I’ve had it for a few months now, and it’s doing wonderfully. It’s a great reminder of the natural beauty that plants can bring to any home.",
    name: "Nina K.",
    location: "Vancouver, BC",
    date: "July 9, 2024",
    rating: "★★★★★"
  },
  {
    id: 6,
    img: avatarThree,
    testimonial: "The Peace Lily really brings life to my office space. So lush and vibrant! It has a beautiful shape and has quickly become a focal point in my workspace. I’ve noticed a difference in the overall atmosphere since adding it, and it’s been a wonderful addition. It was shipped quickly and arrived in excellent condition, ready to be displayed. I’m beyond happy with this purchase.",
    name: "Jess R.",
    location: "Toronto, ON",
    date: "September 2, 2024",
    rating: "★★★★★"
  }
];



const PlantDirectory = () => {

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
    }, 9000); 

    return () => clearInterval(interval); 
  }, [testimonials.length]);

  // Function to handle clicking on bullet
  const handleBulletClick = (index) => {
    setCurrentTestimonialIndex(index);
  };

  return (
    <div>
      <Helmet>
        <title>SWEET BAE | Plant Directory</title>
        <meta name="description" content="Explore our wide range of plants and pots in the SWEET BAE shop. From small succulents to large tropicals, enjoy a 30% spring discount on all our products!" />
      </Helmet>
      <AnnouncementBar />
          <div className="shop-hero hero">
              <div className="hero-content">
                <h1>Plant Directory</h1>
              </div>
          </div> 
          <section>
              <p className="plant-intro">Find the perfect plants for your garden, big small. Search by plant name or specify your plant requirements and our handy plant finder will find the perfect match for you!</p>               
          </section>
          <section>
            <div className="shop-grid">
              {plantItems.map(plant => (
                <Link className="plant-item" to={plant.path} reloadDocument key={plant.id}>
                  {plant.label && <div className="label">{plant.label}</div>}
                    <div className="shop-item-image">
                        <img src={plant.img} alt={plant.name} />
                    </div>
                  <div className="description">
                    <div>
                      <span className="category"><p>{plant.category}</p></span>
                      <span className="name"><h2>{plant.name}</h2></span>
                    </div>
                  </div>
                  <div className="meta">
                    <span className="ratings">{plant.ratings}</span>
                    <span className="reviews">{plant.reviews}</span>
                  </div>
                </Link>
              ))}
            </div>
          </section>

      <section>
      <div className="shop-testimonial">
        <h2>We make people smile!</h2>
        <h3>What our customers are saying?</h3>
        <div className="testimonial-container">
          <div className="testimonials">
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className={`testimonial ${
                  index === currentTestimonialIndex ? 'active' : 'inactive'
                }`}
              >
                <div className="testimonial-image">
                  <img src={testimonial.img} alt={testimonial.name} />
                </div>
                <p>{testimonial.testimonial}</p>
                <div className="testimonial-rating">{testimonial.rating}</div>
                <div className="testimonial-name">
                  <p>{testimonial.name}</p>
                </div>
                <div className="testimonial-location-date">
                  <p>
                    {testimonial.location}, {testimonial.date}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="testimonial-bullets">
            {testimonials.map((_, index) => (
              <span
                key={index}
                className={`bullet ${
                  index === currentTestimonialIndex ? 'active' : ''
                }`}
                onClick={() => handleBulletClick(index)}
              />
            ))}
          </div>
        </div>
      </div>
      </section>
      <section>
        <div className="shop-bottom-hero promo-section">
          <div className="promo-content">
            <h2>Not sure where to start? We do.</h2>
            <h3>We're here to help.</h3>
            <Link to="/contact" reloadDocument><button>Talk to us</button></Link>
          </div>
        </div>
        </section>
    </div>
  )
}

export default pageTransition(PlantDirectory);
