import React, { useState } from 'react';
import './CaseStudies.css';
import { MdDescription, MdOutlineReportProblem, MdAutoFixHigh, MdCloudDone  } from "react-icons/md";
import { IoMdClose, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import {
    officePlantOne,
    officePlantTwo,
    officePlantThree
} from '../../../assist/index';
import { Helmet } from 'react-helmet';
import pageTransition from '../../../variants/pageTransition';


const officeCaseStudies = [
    {
        description: "Implemented a comprehensive office plant installation program to enhance workplace environments, focusing on air quality improvement and aesthetic enhancement.",
        challenge: "Many office spaces lacked vitality and connection to nature, contributing to lower worker satisfaction and productivity. There was a need for a greener, more vibrant office environment.",
        solution: "Designed and executed a customized plant installation plan for various office layouts, incorporating low-maintenance, air-purifying plants. Included professional maintenance schedules to ensure plant health without imposing on the daily responsibilities of office staff.",
        outcome: "The office plant installations significantly improved the ambiance and air quality of the workspaces, leading to higher employee satisfaction and productivity. Feedback from office staff was overwhelmingly positive, noting the psychological and visual benefits of the enhanced greenery."
    }
];


const OfficePlantInstall = () => {

    const [modal, setModal] = useState({ isOpen: false, imgSrc: '' });
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const images = [officePlantOne, officePlantTwo, officePlantThree];

    const openModal = (imgSrc) => {
        setModal({ isOpen: true, imgSrc });
    };

    const closeModal = () => {
        setModal({ isOpen: false, imgSrc: '' });
    };

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <section>
            <Helmet>
                <title>Office Plant Installation</title>
                <meta name="description" content="Enhance your office environment with our tailored plant installation program designed to improve air quality and workplace aesthetics, promoting greater employee satisfaction and productivity." />
            </Helmet>
            <p className="discount">30% off spring sale</p>
            <div>
                <div className="case-hero-image">
                    <div className="case-hero">
                        <h1>
                            Office Plant Installation
                        </h1>
                    </div>
                </div>
            </div>
            <div className="case-images">
                <IoIosArrowBack className="arrow left" onClick={prevImage} />
                <div className="image-container">
                    <img
                        src={images[(currentImageIndex - 1 + images.length) % images.length]}
                        className="left-image"
                        alt="Previous Design"
                    />
                    <img
                        src={images[currentImageIndex]}
                        className="main-image"
                        alt="Current Design"
                        onClick={() => openModal(images[currentImageIndex])}
                    />
                    <img
                        src={images[(currentImageIndex + 1) % images.length]}
                        className="right-image"
                        alt="Next Design"
                    />
                </div>
                <IoIosArrowForward className="arrow right" onClick={nextImage} />
            </div>
            {modal.isOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}><IoMdClose /></span>
                        <img src={modal.imgSrc} alt="Enlarged view" />
                    </div>
                </div>
            )}
            <div className="card-container">
                {officeCaseStudies.map((study, index) => (
                    <div key={index} className="card">
                        <div className="card-item">
                            <MdDescription className="icon" />
                            <h2>Description</h2>
                            <p>{study.description}</p>
                        </div>
                        <div className="card-item">
                            <MdOutlineReportProblem className="icon" />
                            <h2>Challenge</h2>
                            <p>{study.challenge}</p>
                        </div>
                        <div className="card-item">
                            <MdAutoFixHigh className="icon" />
                            <h2>Solution</h2>
                            <p>{study.solution}</p>
                        </div>
                        <div className="card-item">
                            <MdCloudDone className="icon" />
                            <h2>Outcome</h2>
                            <p>{study.outcome}</p>
                        </div>
                    </div>
                ))}
            </div>
            <hr/>
        </section>
    )
}

export default pageTransition(OfficePlantInstall);